import type { ProductCard } from "@/lib/centra/formatters/formatProductCards/formatProductCard";

const formatProductsForPlp = (products: ProductCard[] | undefined) => {
  // divide into arrays of 8
  const formattedProducts: {
    products: ProductCard[];
    editorial: string;
  }[] = [];
  if (!products) return formattedProducts;
  for (let i = 0; i < products.length; i += 8) {
    formattedProducts.push({
      products: products.slice(i, i + 8),
      editorial: "editorial", //TODO: update this with StoryBlok data
    });
  }
  return formattedProducts;
};
export default formatProductsForPlp;
