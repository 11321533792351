import usePrefersReducedMotion from "./usePrefersReducedMotion";

const useScrollToTop = () => {
  const isBrowser = () => typeof window !== "undefined";
  const prefersReducedMotion = usePrefersReducedMotion();

  function scrollToTop() {
    if (!isBrowser()) return;
    window.scrollTo({
      top: 0,
      behavior: prefersReducedMotion ? "auto" : "smooth",
    });
  }
  return scrollToTop;
};

export default useScrollToTop;
