"use client";
import useModal from "@/lib/hooks/useModal";
import { useOnClickOutside } from "@/lib/hooks/useOnClickOutside";
import clsx from "clsx";
import { useCallback, useRef } from "react";
import styles from "./popover.module.css";

const Popover = ({ children, modal, className }: any) => {
  const { isOpen, closeModal } = useModal(modal, () => {}, false);

  const modalRef = useRef<HTMLDivElement | null>(null);
  const handleClickOutside = useCallback(
    (e: any) => {
      if (!isOpen || !modalRef.current) return;
      if (modalRef.current.contains(e.target) || e.target === modalRef.current)
        return;
      closeModal();
    },
    [isOpen, closeModal, modalRef]
  );

  useOnClickOutside([modalRef], handleClickOutside);

  return (
    <div
      ref={modalRef}
      className={clsx(styles.modal, isOpen ? styles.open : null, className)}
    >
      {children}
    </div>
  );
};
export default Popover;
