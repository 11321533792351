import env from "@/env.mjs";
import type {
  FieldOrFunction,
  ICentraFilterUri,
  IFilterGroup,
  ISortOrderValue
} from "@/types/centra";

import type { CentraProduct } from "../../centra/atomicSetup";
import type { ProductCard } from "../../centra/formatters/formatProductCards/formatProductCard";
import getCategoryId from "./getCategoryId";
import type { IFormattedFilters } from "./types";
import type { RawFilterResponse } from "./useProductFilter";

export type GetProductsReturn = Awaited<ReturnType<typeof getProducts>>;

type IFilterBodyTypes =
  | boolean
  | number
  | string
  | undefined
  | string[]
  | ISortOrderValue[]
  | ICentraFilterUri;

interface IFilterBody {
  uri: ICentraFilterUri | undefined;
  relatedProducts: boolean;
  limit: number;
  skipFirst: number;
  search: string | undefined;
  sortOrder: ISortOrderValue[];
  [index: string]: IFilterBodyTypes;
}

export type GetProducts = <PC extends ProductCard, ExtraData>({
  cardFormatter,
  category,
  filters,
  productIds,
  search,
  sortOrder,
  page,
  productsPerPage,
  extraDataFetcher,
  customerToken
}: // new
{
  cardFormatter: (product: CentraProduct, data?: ExtraData) => PC;
  category: string[] | [];
  filters?: IFormattedFilters;
  filterGroups?: IFilterGroup[];
  productIds?: string[] | undefined;
  search?: string;
  sortOrder?: ISortOrderValue[];
  page?: number;
  productsPerPage?: number;
  customerToken?: string;
  extraDataFetcher?: (productIds: string[]) => Promise<ExtraData>;
}) => Promise<{
  products: PC[];
  filter: any[];
  token: string;
  productCount: number;
  hero: any;
}>;

export type GetProductsParameters = Parameters<typeof getProducts>[0];

const formatFilterGroups = (
  filterGroups: IFilterGroup[]
): IFormattedFilters => {
  const group: IFormattedFilters = filterGroups.map((group) => ({
    name: group.name,
    fieldOrFunction: group.fieldOrFunction as FieldOrFunction,
    required: group.required ?? false,
    labelFieldOrFunction: group.labelFieldOrFunction,
    values: undefined,
    toggle: (_value: string) => {},
    add: (_value: string) => {},
    remove: (_value: string) => {},
    clear: () => {},
    isOpen: () => false,
    toggleOpen: () => {},
    isActive: (_value: string) => false
  }));
  return group;
};

export const getProducts: GetProducts = async ({
  cardFormatter,
  category,
  filters = [],
  filterGroups,
  productIds,
  search = "",
  sortOrder = [],
  page = 0,
  productsPerPage = 8,
  extraDataFetcher,
  customerToken
}) => {
  const apiUrl = env.NEXT_PUBLIC_CENTRA_CHECKOUT_API;

  if (filters.length === 0 || filters === undefined) {
    if (filterGroups) filters = formatFilterGroups(filterGroups);
  }

  const categoryUri =
    category?.length > 0
      ? search
        ? undefined
        : { uri: category.join("/"), for: ["category"] }
      : undefined;

  const filterBody: IFilterBody = {
    uri: categoryUri,
    products: productIds,
    relatedProducts: true,
    limit: productsPerPage,
    skipFirst: page * productsPerPage,
    search: search,
    sortOrder: sortOrder
  };

  if (filters) {
    const activeFilters = filters.filter(
      (filter) => filter.values?.length && filter.values.length > 0
    );
    if (activeFilters.length > 0) {
      filterBody["onlyAvailable"] = true;
    }
    filters.forEach((filter) => {
      if (filter.values) {
        typeof filter.fieldOrFunction === "string"
          ? (filterBody[filter.fieldOrFunction] = filter.values)
          : filter.fieldOrFunction !== undefined &&
            (filterBody[filter.fieldOrFunction([]).field] = filter.values);
      }
    });
  }
  if (category[0] !== "all") {
    const catIds = await getCategoryId(category, customerToken);
    filterBody["categories"] = [catIds];
  }

  try {
    // RawFilterResponse;
    const res = await fetch(`${apiUrl}/products`, {
      headers: {
        "API-token": customerToken || "",
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify(filterBody),
      cache: "no-store"
    });

    if (!res.ok) throw new Error("Error fetching products");
    const resData: RawFilterResponse = await res.json();
    let products = resData.products;
    const extraData = extraDataFetcher
      ? await extraDataFetcher(products.map((product) => product.product))
      : undefined;
    /* Remove engraving from PLP's */
    products = products.filter((product) => product.uri !== "engraving");

    const formattedProducts = products.map((product) => {
      return cardFormatter(product, extraData);
    });
    let hero;
    if (
      category.length === 1 &&
      (category[0] === "caps" || category[0] === "all")
    ) {
      hero = true;
      filterBody["dis_shero_text"] = ["1"]; // Only show hero displays on main page
      const heroRes = await fetch(`${apiUrl}/products`, {
        headers: {
          "API-token": customerToken || "",
          "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify(filterBody),
        cache: "no-store"
      });
      if (!heroRes.ok) throw new Error("Error fetching products");
      const heroResData: RawFilterResponse = await heroRes.json();
      let heroProducts = heroResData.products;

      const formattedHeroProducts = heroProducts.map((product) => {
        return cardFormatter(product, extraData);
      });
      hero = {
        ...heroResData,
        products: formattedHeroProducts
      };
    }

    const filterFields = filters.map((filter) => filter.fieldOrFunction);
    const formattedFilters =
      filters.length > 0
        ? resData.filter.filter(
            (centraFilter) =>
              filterFields.findIndex((field: any) =>
                typeof field === "string"
                  ? field === centraFilter.field
                  : field(resData.filter).field === centraFilter.field
              ) !== -1
          )
        : [];
    return {
      ...resData,
      products: formattedProducts,
      filter: formattedFilters,
      hero: hero ? hero : {}
    };
  } catch (error) {
    throw new Error("Unexpected error");
  }
};
