"use client";
import clsx from "clsx";

import Popover from "@/components/molecules/Popover";

import useModal from "@/lib/hooks/useModal";
import { SortOrder } from "@/lib/hooks/useProductFilter/useProductFilter";
import styles from "./sort-modal.module.css";

const SortModal = ({ sortOrder }: { sortOrder: SortOrder }) => {
  const { closeModal } = useModal("sort", () => {});
  return (
    <Popover modal={"sort"} className={styles.modal}>
      <div className={styles.modal__content}>
        <div className={styles["sort-options"]}>
          {sortOrder.options.map((option) => {
            const active = sortOrder.sortOrder === option.key;
            return (
              <button
                key={option.key}
                className={clsx(styles["sort-option"], active && styles.active)}
                disabled={active}
                onClick={() => {
                  sortOrder.setSortOrder(option.key);
                  closeModal();
                }}
              >
                {option.display}
              </button>
            );
          })}
        </div>
      </div>
    </Popover>
  );
};

export default SortModal;
