"use client";

import { Button } from "@/components/atoms";
import { PropsWithChildren } from "react";

const SortButton = ({ children }: PropsWithChildren) => {
  return (
    <Button color="toggle" modal={"sort"} popover label="sort">
      {children}
    </Button>
  );
};

export default SortButton;
