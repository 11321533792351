const FilterIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 10.5C11.1739 10.5 12.1588 9.69093 12.4275 8.6H21V7.4H12.4275C12.1588 6.30907 11.1739 5.5 10 5.5C8.82612 5.5 7.8412 6.30907 7.57246 7.4H3V8.6H7.57246C7.8412 9.69093 8.82612 10.5 10 10.5ZM10 9.3C10.718 9.3 11.3 8.71797 11.3 8C11.3 7.28203 10.718 6.7 10 6.7C9.28203 6.7 8.7 7.28203 8.7 8C8.7 8.71797 9.28203 9.3 10 9.3Z"
        fill="black"
        style={{ fill: "var(--svg-color)" }}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4275 16.6C16.1588 17.6909 15.1739 18.5 14 18.5C12.8261 18.5 11.8412 17.6909 11.5725 16.6H3V15.4H11.5725C11.8412 14.3091 12.8261 13.5 14 13.5C15.1739 13.5 16.1588 14.3091 16.4275 15.4H21V16.6H16.4275ZM15.3 16C15.3 16.718 14.718 17.3 14 17.3C13.282 17.3 12.7 16.718 12.7 16C12.7 15.282 13.282 14.7 14 14.7C14.718 14.7 15.3 15.282 15.3 16Z"
        fill="black"
        style={{ fill: "var(--svg-color)" }}
      />
    </svg>
  );
};

export default FilterIcon;
