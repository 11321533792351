"use client";
import ChevronDown from "@/icons/ChevronDown";
import Close from "@/icons/Close";
import FilterIcon from "@/icons/Filter";
import {
  ITags,
  SortOrder,
} from "@/lib/hooks/useProductFilter/useProductFilter";
import { useTranslations } from "next-intl";
import FilterButton from "../PlpButtons/FilterButton";
import SortButton from "../PlpButtons/SortButton";
import SortModal from "../PlpModals/Sort";
import styles from "./plp-filter-section.module.css";

type Props = {
  productCount?: number;
  tags?: ITags;
  showFilters?: boolean;
  showSort?: boolean;
  sortOrder: SortOrder;
  clearFilters?: () => void;
};

const PlpTopBar = ({
  productCount,
  tags,
  showFilters,
  showSort,
  sortOrder,
  clearFilters,
}: Props) => {
  const t = useTranslations();
  const hasTags = tags && tags.length > 0;
  let sortByDisplay =
    sortOrder?.active?.display || sortOrder?.options[0].display;

  if (showFilters === undefined) showFilters = true;
  if (showSort === undefined) showSort = true;
  return (
    <section className={styles.filters}>
      <div className={styles.wrap}>
        <div>
          {hasTags && (
            <div className={styles.tags}>
              {tags?.map((tag, key) => (
                <span
                  role="button"
                  tabIndex={0}
                  className={styles.tag}
                  key={key}
                  onClick={tag.toggle}
                >
                  <span>{tag.value}</span>
                  <Close />
                </span>
              ))}

              {(tags?.length ?? 0) >= 2 && (
                <span
                  tabIndex={0}
                  role="button"
                  onClick={clearFilters}
                  className={styles.clearTag}
                >
                  <span>Remove all</span>
                  <Close />
                </span>
              )}
            </div>
          )}

          <div className={styles.count}>{productCount} products</div>
        </div>

        <div className={styles["filters-bar"]}>
          {showFilters && (
            <FilterButton>
              <span>
                <span>{t("productListingPage.filterBy")}</span>
                <FilterIcon />
              </span>
            </FilterButton>
          )}
          {showSort && (
            <div style={{ position: "relative" }}>
              <SortButton>
                <span>
                  <span>
                    {t("productListingPage.sortBy")} {sortByDisplay}
                  </span>
                  <ChevronDown />
                </span>
              </SortButton>
              <SortModal sortOrder={sortOrder} />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default PlpTopBar;
