"use client";
import clsx from "clsx";

import { Button } from "@/components/atoms";
import { Accordion, AccordionGroup } from "@/components/atoms/Accordion";

import type { FilterModalProps } from "../../../hooks/usePlp";
import styles from "./filter-modal.module.css";

import Modal from "@/components/molecules/Modal";
import useModal from "@/lib/hooks/useModal";

function filterSort(filters: any, method: string) {
  switch (method) {
    case "size":
      const reference = [
        "XXXS",
        "XXS",
        "XS",
        "S",
        "M",
        "L",
        "XL",
        "XXL",
        "XXXL"
      ];
      return [...filters].sort(
        (a, b) => reference.indexOf(a.value) - reference.indexOf(b.value)
      );
    default:
      return [...filters].sort((a, b) => {
        const nameA = a.value.toUpperCase();
        const nameB = b.value.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
  }
}

const OldFilterModalContent = ({
  closeModal,
  filters,
  productCount
}: {
  closeModal: () => void;
  filters: FilterModalProps;
  productCount?: number;
}) => {
  const {
    filterObjects,
    tags,
    clearFilters
    // categories, params
  } = filters;

  const colors = filterObjects
    ?.find((obj) => obj?.name === "color")
    ?.filters?.filter((filter) => filter.display === true);
  const sizes = filterObjects
    ?.find((obj) => obj?.name === "size")
    ?.filters?.filter((filter) => filter.display === true);
  const shapes = filterObjects
    ?.find((obj) => obj?.name === "shape")
    ?.filters?.filter((filter) => filter.display === true);
  const series = filterObjects
    ?.find((obj) => obj?.name === "series")
    ?.filters?.filter((filter) => filter.display === true);
  const climate = filterObjects
    ?.find((obj) => obj?.name === "climate")
    ?.filters?.filter((filter) => filter.display === true);
  const fabric = filterObjects
    ?.find((obj) => obj?.name === "fabric")
    ?.filters?.filter((filter) => filter.display === true);
  const material = filterObjects
    ?.find((obj) => obj?.name === "material")
    ?.filters?.filter((filter) => filter.display === true);
  const FilterBody = ({ filters }: { filters: any }) => {
    return (
      <div className={styles.size__content}>
        {filters?.map(
          (filter: any) =>
            filter.display && (
              <button
                key={filter.value}
                className={clsx(
                  styles.item,
                  styles.size__item,
                  filter.active && styles.active
                )}
                disabled={!filter?.available}
                onClick={filter.toggle}
              >
                <span>{filter?.value}</span>
              </button>
            )
        )}
      </div>
    );
  };
  const AccordionElement = ({
    title,
    filters
  }: {
    title: string;
    filters: any;
  }) => {
    const activeFilters = filters.filter(
      (filter: any) => filter.active === true
    );
    let descriptor = "";
    if (activeFilters.length) {
      if (activeFilters.length === 1) {
        descriptor = `${activeFilters[0]?.value}`;
      } else {
        descriptor = `${activeFilters.length} selected`;
      }
    }
    if (filters.length < 2) {
      return null;
    }
    return (
      <Accordion
        title={title}
        descriptor={descriptor}
        styleProps={{
          accordion: clsx(styles.accordion, styles.last),
          trigger: styles.trigger
        }}
      >
        <FilterBody filters={filters} />
      </Accordion>
    );
  };
  return (
    <>
      <div className={styles.module__filters}>
        <AccordionGroup multiple defaultValue={["color", "size"]}>
          {colors?.length !== 0 && (
            <Accordion
              title="Color"
              styleProps={{
                accordion: styles.accordion,
                trigger: styles.trigger
              }}
            >
              <div className={styles.accordion__content}>
                {colors &&
                  colors.map(
                    (color) =>
                      color.display && (
                        <button
                          key={color.value}
                          className={clsx(
                            styles.item,
                            styles.color__blob,
                            color.active && styles.active
                          )}
                          disabled={!color?.available}
                          onClick={color.toggle}
                        >
                          <div>
                            <span>{color?.value}</span>
                            <span
                              className={styles.color__indicator}
                              style={{
                                background: `${color?.colorCode}` ?? "lightgray"
                              }}
                            />
                          </div>
                        </button>
                      )
                  )}
              </div>
            </Accordion>
          )}
          {sizes?.length !== 0 && (
            <AccordionElement
              title="Size"
              filters={filterSort(sizes, "size")}
            />
          )}
          {material?.length !== 0 && (
            <AccordionElement
              title="Material"
              filters={filterSort(material, "material")}
            />
          )}
          {series?.length !== 0 && (
            <AccordionElement
              title="Series"
              filters={filterSort(series, "series")}
            />
          )}
          {shapes?.length !== 0 && (
            <AccordionElement
              title="Shape"
              filters={filterSort(shapes, "shapes")}
            />
          )}
          {climate?.length !== 0 && (
            <AccordionElement
              title="Climate"
              filters={filterSort(climate, "climate")}
            />
          )}
          {fabric?.length !== 0 && (
            <AccordionElement
              title="Fabric properties"
              filters={filterSort(fabric, "fabric")}
            />
          )}
        </AccordionGroup>
      </div>
      {/* footer */}
      <div className={styles.module__footer}>
        <span>{productCount} results</span>
        <div className={styles.module__footer_inner}>
          <Button
            color="transparent"
            outlined
            full
            onClick={() => {
              clearFilters();
              closeModal();
            }}
          >
            Clear all
          </Button>
          <Button color="black" full onClick={closeModal}>
            Apply
          </Button>
        </div>
      </div>
    </>
  );
};

const FilterModal = ({
  filters,
  productCount
}: {
  filters: FilterModalProps;
  productCount?: number;
}) => {
  const { closeModal } = useModal("filter", () => {});
  return (
    <Modal
      title="Filters"
      modal={"filter"}
      always
      className={clsx(styles.filters)}
      footer
    >
      <div className={styles.modalInner}>
        <OldFilterModalContent
          closeModal={closeModal}
          filters={filters}
          productCount={productCount}
        />
      </div>
    </Modal>
  );
};
export default FilterModal;
