"use client";
import { usePlp } from "../../hooks/usePlp";
import { type InitialPlpData } from "../../serverUtils/getInitialPlpData";
import PlpModals from "../PlpModals";
import PlpTopBar from "../PlpTopBar";
import ProductsSection from "../ProductsSection/ProductsSection";

type Props = {
  story: any;
  initialProductData: InitialPlpData["products"];
  currentCategory: string[];
  categories: InitialPlpData["categories"];
  params?: { category: string[]; locale: string };
  initialFilterData?: InitialPlpData["initialFilterData"];
};

const ClientPlp = ({
  story,
  categories,
  params,
  initialProductData,
  currentCategory,
  initialFilterData
}: Props) => {
  const { ModalsProps, clearFilters, ProductsData } = usePlp(
    initialProductData,
    currentCategory,
    initialFilterData
  );

  return (
    <>
      <PlpTopBar
        productCount={ProductsData?.productCount}
        tags={ModalsProps.filterModalProps.tags ?? []}
        showFilters={story?.showFilters}
        clearFilters={clearFilters}
        showSort={story?.showSort}
        sortOrder={ModalsProps.SortOrderProps}
      />
      <div style={{ position: "relative" }}>
        <PlpModals
          filters={{
            ...ModalsProps.filterModalProps,
            categories,
            params
          }}
          productCount={ProductsData?.productCount}
        />
      </div>

      <ProductsSection data={ProductsData} />
    </>
  );
};

export default ClientPlp;
