"use client";

import { Button, ProductCard } from "@/components/atoms";
import SkeletonPC from "@/components/atoms/ProductCard/SkeletonPC";
import ProductGrid from "@/components/molecules/ProductGrid";
import useIntersection from "@/lib/hooks/useIntersection";
import useScrollToTop from "@/lib/hooks/useScrollToTop";
import { useTranslations } from "next-intl";
import type { PLPContent, PlpProductsSectionProps } from "../../hooks/usePlp";

const ProductsSection = ({ data }: { data: PlpProductsSectionProps }) => {
  const t = useTranslations();
  const scrollToTop = useScrollToTop();
  const { plpContent, fetchNextPage, hasNextPage, isFetching } = data;
  const callbackFunction = (
    entries: IntersectionObserverEntry[],
    _observer: IntersectionObserver
  ) => {
    const [entry] = entries;
    if (entry.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  };
  const { Ref } = useIntersection(callbackFunction);

  const products = plpContent.reduce(
    (acc: PLPContent[number]["products"], curr) => {
      return [...acc, ...curr.products];
    },
    []
  );

  return (
    <section style={{ display: "flex", flexDirection: "column", gap: "6rem" }}>
      <>
        <ProductGrid id="grid">
          {products?.map((product, i) => {
            return (
              <ProductCard
                product={product}
                key={`product-${product?.core?.slug}-${i}`}
                priority={i < 5}
              />
            );
          })}
          {hasNextPage && (
            <>
              <div id="observer" ref={Ref}>
                <SkeletonPC />
              </div>
              <SkeletonPC /> <SkeletonPC /> <SkeletonPC />
            </>
          )}
        </ProductGrid>
      </>

      {!hasNextPage && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "1.5rem",
            padding: "24px 0"
          }}
        >
          <Button outlined onClick={scrollToTop}>
            {t("productListingPage.backToTop")}
          </Button>
        </div>
      )}
    </section>
  );
};

export default ProductsSection;
