"use client";
import { VIP_MARKET } from "@/consts";
import { useAuth } from "@/lib/hooks/useAuth";
import { useIsomorphicLayoutEffect } from "@/lib/hooks/useIsomorphicLayoutEffect";
import { useSelection } from "@/lib/hooks/useSelection";
import { usePathname, useRouter } from "@/navigation";
import React, { useMemo, useTransition } from "react";
import { type LiteralUnion } from "react-hook-form";

export const UserGroups = {
  All: "all_users",
  LoggedIn: "logged_in_users",
} as const;

export type UserGroups = (typeof UserGroups)[keyof typeof UserGroups];

interface Props {
  children: React.ReactNode;
  userGroups?: LiteralUnion<UserGroups, string | number>[];
  fallback?: React.ReactNode;
}

const defaultShow = [UserGroups.All];

export const PageGuard = ({
  children,
  userGroups = defaultShow,
  fallback,
}: Props) => {
  const { isLoggedIn, isLoading: authLoading, vip } = useAuth();
  const [isPending, startTransition] = useTransition();
  const { market } = useSelection();
  const pathname = usePathname();

  const router = useRouter();

  useIsomorphicLayoutEffect(() => {
    if (authLoading) return;
    if (vip) return;

    if (userGroups.includes(UserGroups.LoggedIn) && market !== VIP_MARKET) {
      /**
       * We use a React state transition to extend the loading state beyond the page
       */
      startTransition(() => {
        router.replace(`/login?redirect=${pathname}`);
      });

      return;
    }
  }, [userGroups, isLoggedIn, router, authLoading, pathname]);

  const isLoading = isPending || authLoading;

  const showFallback = useMemo(
    () => userGroups.includes(UserGroups.LoggedIn),
    [userGroups],
  );

  if (fallback && showFallback && isLoading) return <>{fallback}</>;

  return <>{children}</>;
};
