import { useMemo } from "react";

import type { ISortOption } from "@/types/centra";
import { useQueryState } from "../../useQueryState";

const sortOrders: Record<ISortOption["key"], ISortOption> = {
  default: {
    key: "default",
    display: "Bestsellers",
    value: [],
  },
  newest: {
    key: "newest",
    display: "Newest first",
    value: [{ field: "createdAt", order: "desc" }],
  },
  highestPrice: {
    key: "highestPrice",
    display: "Highest price first",
    value: [{ field: "priceAsNumber", order: "desc" }],
  },
  lowestPrice: {
    key: "lowestPrice",
    display: "Lowest price first",
    value: [{ field: "priceAsNumber", order: "asc" }],
  },
};

const options = Object.values(sortOrders);

export const useSortOrder = () => {
  const [sortKey, setSortKey] = useQueryState<ISortOption["key"]>(
    "sort",
    "default",
    { fallback: true },
  );

  const active = useMemo(
    () =>
      options.find((option) => option.key === sortKey) || sortOrders.default,
    [sortKey],
  );

  return {
    setSortOrder: setSortKey,
    sortOrder: sortKey,
    options,
    active,
  };
};

export type SortOrder = ReturnType<typeof useSortOrder>;
