import { useRouter, useSearchParams } from "next/navigation";
import { useCallback } from "react";
import useHasHydrated from "./useHasHydrated";

export const useQueryState = <T extends string | null>(
  key: string,
  initialState?: T,
  { shallow = true, replace = false, fallback = false } = {}
) => {
  const hasHydrated = useHasHydrated();
  const router = useRouter();
  const searchParams = useSearchParams();

  const val = searchParams.get(key);

  const setValue = useCallback(
    (value: T | ((arg: T | null) => T)) => {
      const newParams = new URLSearchParams(searchParams.toString());
      const newValue =
        typeof value === "function"
          ? value((val as T) || initialState || null)
          : value;
      if (!newValue) {
        newParams.delete(key);
      } else {
        newParams.set(key, newValue);
      }

      const newUrl = `${window.location.pathname}?${newParams.toString()}`;

      if (shallow) {
        if (replace) {
          window.history.replaceState({}, "", newUrl);
        } else {
          window.history.pushState({}, "", newUrl);
        }
      } else {
        if (replace) {
          router.replace(newUrl);
        } else {
          router.push(newUrl);
        }
      }
    },
    [initialState, key, replace, router, searchParams, shallow, val]
  );

  if (fallback) {
    return [hasHydrated ? (val as T) : initialState, setValue] as const;
  }

  return [val as T, setValue] as const;
};
