import { InitialPlpData } from "@/lib/plp/serverUtils/getInitialPlpData";
import { dedupe } from "@/lib/utils/dedupe";
import { ICentraFilterGroup } from "@/types/centra";
import isEqual from "lodash.isequal";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  FunctionValues,
  getFunctionFromString
} from "../../plpUseProductsFilter/functionMap";
import type { StringOrFilterValue } from "../../plpUseProductsFilter/types";
import { useBoolean } from "../../useBoolean";
import type { IFilters } from "../types";

const formatInitialFilters: (
  initial?: InitialPlpData["initialFilterData"]
) => IFilters | undefined = (initial) =>
  initial?.map((group) => ({
    name: group.name,
    fieldOrFunction: group.fieldOrFunction as StringOrFilterValue,
    required: group.required ?? false,
    labelFieldOrFunction: group.labelFieldOrFunction,
    values: undefined,
    isOpen: true // all accordions open by default
  }));

const useFilters: (initProps?: InitialPlpData["initialFilterData"]) => any = (
  initProps
) => {
  const [isModalOpen, toggleModal] = useBoolean(true);
  const initialFilterRef = useRef(formatInitialFilters(initProps));
  const [filters, setFilters] = useState<IFilters | undefined>(
    initialFilterRef.current
  );

  /**
   * Syncs the filters to the URL in append mode for readability.
   * Only execute once filters is dirty
   */
  useEffect(() => {
    // On first render, don't sync it will wipe out the url filters
    if (initialFilterRef.current === filters) return;
    // Set filters to url params
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    for (const filter of filters ?? []) {
      const values = filter.values;
      searchParams.delete(filter.name); // remove and reappend to avoid duplicates
      values?.forEach((value) => searchParams.append(filter.name, value));
    }
    window.history.replaceState({}, "", url.toString());
  }, [filters]);

  /**
   * Syncs the filters from the URL to the state.
   * Supports both comma separated and append mode
   * Runs only once on mount, does not perform a constant sync
   */
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    for (const filter of filters ?? []) {
      const values = searchParams
        .getAll(filter.name)
        .map((v) => v.split(","))
        .flat();
      if (!values?.length) continue;

      setFilters((state) => {
        return state?.map((f) => {
          if (f.name === filter.name) {
            return { ...f, values: dedupe([...(f.values ?? []), ...values]) };
          }
          return f;
        });
      });
    }

    // We only want to run this on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const modifyValue = useCallback(
    (group: string, value: string, add: boolean, remove: boolean) => {
      const currentGroup = filters?.filter((f) => f.name === group)?.[0];
      if (!currentGroup) return;
      const vals = currentGroup.values ?? [];
      const filteredValues = vals.filter((v: string) => !isEqual(v, value));
      if (filteredValues.length < vals.length) {
        if (remove) {
          setFilters((state) =>
            state?.map((f) => {
              if (f.name === group) {
                return { ...f, values: filteredValues };
              }
              return f;
            })
          );
        }
        return;
      }
      if (add) {
        setFilters((state) =>
          state?.map((f) => {
            if (f.name === group) {
              return { ...f, values: [...vals, value] };
            }
            return f;
          })
        );
      }
    },
    [filters]
  );

  const toggle = useCallback(
    (group: string, value: string) => modifyValue(group, value, true, true),
    [modifyValue]
  );

  const add = useCallback(
    (group: string, value: string) => modifyValue(group, value, true, false),
    [modifyValue]
  );

  const remove = useCallback(
    (group: string, value: string) => modifyValue(group, value, false, true),
    [modifyValue]
  );

  const clear = useCallback((group: string) => {
    setFilters((state) =>
      state?.map((g) => {
        if (g.name === group) {
          return { ...g, values: [] };
        }
        return g;
      })
    );
  }, []);

  const isActive = useCallback(
    (group: string, value: string) => {
      const currentGroup = filters?.filter((f) => f.name === group)?.[0];
      if (!currentGroup) return false;
      return currentGroup.values?.includes(value);
    },
    [filters]
  );

  // toggles the group of filters open/closed
  const toggleGroupOpen = useCallback((group: string) => {
    setFilters((state) =>
      state?.map((f) => {
        if (f.name === group) {
          return { ...f, isOpen: !f.isOpen };
        }
        return f;
      })
    );
  }, []);

  const formatFiltersForExport: any = useMemo(() => {
    return filters?.map((group) => ({
      name: group.name,
      fieldOrFunction: group.fieldOrFunction.startsWith("func__")
        ? (getFunctionFromString(group.fieldOrFunction as FunctionValues) as (
            values: ICentraFilterGroup[]
          ) => ICentraFilterGroup)
        : group.fieldOrFunction,
      required: group.required,
      labelFieldOrFunction: group.labelFieldOrFunction,
      values: group.values ?? undefined,
      toggle: (value: string) => toggle(group.name, value),
      add: (value: string) => add(group.name, value),
      remove: (value: string) => remove(group.name, value),
      clear: () => clear(group.name),
      isOpen: () => filters.find((g) => g.name === group.name)?.isOpen ?? false,
      toggleOpen: () => toggleGroupOpen(group.name),
      isActive: (value: string) => isActive(group.name, value)
    }));
  }, [filters, toggle, add, remove, clear, toggleGroupOpen, isActive]);

  return {
    filters: formatFiltersForExport,
    isModalOpen,
    toggleModal
  };
};

export default useFilters;
