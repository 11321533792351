import { useCallback, useState } from "react";

/**
 * A hook for managing a boolean state.
 *
 * @example usage:
 * { value, setValue, setTrue, setFalse, toggle } = useBoolean()
 */

export function useBoolean(defaultValue: boolean = false) {
  const [value, setValue] = useState(defaultValue);

  const setTrue = useCallback(() => setValue(true), []);
  const setFalse = useCallback(() => setValue(false), []);
  const toggle = useCallback(() => setValue((x) => !x), []);

  return [value, toggle, setTrue, setFalse, setValue] as const;
}
