import { dataAtom, dataComposer } from "@frend-digital/centra";

import type { CentraProduct } from "@/lib/centra/atomicSetup";

import { baseAtomics } from "../../atomicSetup";
const { getMediaBySize } = baseAtomics;

const getStandardMedia = (product: CentraProduct) =>
  getMediaBySize(product, "full");

export const getFirstThumbnail = (product: CentraProduct) => {
  const thumbnails = getStandardMedia(product);
  if (thumbnails?.[0]) return thumbnails[0];
  throw new Error("No thumbnails found");
};

export const getSecondThumbnail = (product: CentraProduct) => {
  const thumbnails = getStandardMedia(product);
  if (thumbnails?.[1]) return thumbnails[1];
  throw new Error("Second thumbnail not found");
};

const getProductGallery = (product: CentraProduct) => {
  const image = dataAtom(getFirstThumbnail, 2)(product);
  const secondImage = dataAtom(getSecondThumbnail, 2)(product);

  const standardProductCardGallery = dataComposer({
    image,
    secondImage,
  });

  return standardProductCardGallery;
};

export default getProductGallery;
