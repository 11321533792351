import { dataAtom, dataComposer } from "@frend-digital/centra";

import type { CentraProduct } from "../atomicSetup";
import { baseAtomics } from "../atomicSetup";
import { getShipping, getSortOrder, getSwatch } from "../localAtomics";
const { getDisplayPriceWithSale, getName, getUri, getProductBreadcrumbs } =
  baseAtomics;

export const formatVariant = (product: CentraProduct) => {
  const name = dataAtom(getName, 2)(product);
  const breadcrumbs = dataAtom(getProductBreadcrumbs, 2)(product);
  const prices = dataAtom(getDisplayPriceWithSale, 2)(product);
  const uri = dataAtom(getUri, 2)(product);
  const swatch = dataAtom(getSwatch, 2)(product);
  const sortOrder = dataAtom(getSortOrder, 2)(product);
  const shipping = dataAtom(getShipping, 2)(product);

  return dataComposer({
    name,
    breadcrumbs,
    prices,
    uri,
    swatch,
    sortOrder,
    shipping
  });
};
