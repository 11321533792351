import { useState } from "react";

export const useSearch = () => {
  const [value, setValue] = useState<string>("");

  const setSearch = (value: string) => {
    setValue(value);
  };

  const clearValue = () => {
    setSearch("");
  };

  return {
    value: value ? value : undefined,
    onValueChange: (e: any) => setSearch(e.target.value),
    setSearch,
    clearValue,
    type: "text",
    placeholder: "Search here...",
  };
};

export type ISearchFilter = ReturnType<typeof useSearch>;
