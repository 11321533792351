import type { IFilterData } from "@/lib/hooks/useProductFilter/useProductFilter";

import { getSizePriority } from "./getSizePriority";

export default function sortSizesByValue(filterGroups: IFilterData) {
  return filterGroups?.map((filterGroup) => {
    if (filterGroup.name !== "SIZE") return filterGroup;
    const sizes = filterGroup.filters;
    return {
      ...filterGroup,
      items: sizes.sort((a, b) => {
        const priorityA = getSizePriority(a);
        const priorityB = getSizePriority(b);

        if (priorityA !== priorityB) {
          return priorityA - priorityB;
        }

        if (priorityA === 2) {
          return b.value.length - a.value.length;
        }
        return sizes.indexOf(a) - sizes.indexOf(b);
      }),
    };
  });
}
