const usePrefersReducedMotion = () => {
  const isBrowser = () => typeof window !== "undefined";

  if (!isBrowser()) return false;

  const prefers = window.matchMedia("(prefers-reduced-motion: reduce)");

  return prefers.matches;
};

export default usePrefersReducedMotion;
