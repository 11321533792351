import { dataAtom, dataComposer } from "@frend-digital/centra";

import { baseAtomics } from "@/lib/centra/atomicSetup";
import type { CentraProduct } from "../../atomicSetup";
import { getComingSoon, getLabels, getSoldOut } from "../../localAtomics";
import getProductCore from "./getCore";
import getProductDescription from "./getDescription";
import getProductGallery from "./getGallery";

const { getProduct, getSku, getProductBreadcrumbs } = baseAtomics;

const formatProductCard = (product: CentraProduct) => {
  const core = getProductCore(product);
  const id = dataAtom(getProduct, 2)(product);
  const gallery = getProductGallery(product);
  const description = getProductDescription(product);
  const comingSoon = dataAtom(getComingSoon, 2)(product);
  const soldOut = dataAtom(getSoldOut, 2)(product);
  const sku = dataAtom(getSku, 2)(product);
  const breadcrumbs = dataAtom(getProductBreadcrumbs, 2)(product);
  const labels = dataAtom(getLabels, 2)(product);

  const bookletProductCard = dataComposer({
    core,
    gallery,
    soldOut,
    comingSoon,
    description,
    id,
    breadcrumbs,
    sku,
    labels
  });
  return bookletProductCard.data;
};

// export const getProductCardData = (product: CentraProduct) =>
//   formatProductCard(product)["data"];

export default formatProductCard;

export type ProductCard = ReturnType<typeof formatProductCard>;
