"use client";

import Button from "@/components/atoms/Button";
import { PropsWithChildren } from "react";

const FilterButton = ({ children }: PropsWithChildren) => {
  return (
    <Button color="toggle" modal={"filter"} label="filter">
      {children}
    </Button>
  );
};

export default FilterButton;
