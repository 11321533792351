import formatProductCard from "@/lib/centra/formatters/formatProductCards/formatProductCard";
import type { GetProductsParameters } from "@/lib/hooks/useProductFilter/getProducts";
import type { IFilterGroup } from "@/types/centra";

const defaultCount = 32;

const getFilterParameters = (
  count = defaultCount,
  productIds: string[] | undefined = undefined,
  categories: string[] = []
) => {
  const category: string[] = categories;
  const filterGroups: IFilterGroup[] = [
    {
      name: "shape",
      fieldOrFunction: "pr_shape.value"
    },
    {
      name: "series",
      fieldOrFunction: "pr_series.value"
    },
    {
      name: "climate",
      fieldOrFunction: "pr_climate_props.label"
    },
    {
      name: "material",
      fieldOrFunction: "pr_material"
    },
    {
      name: "fabric",
      fieldOrFunction: "pr_fabric_props.label"
    },
    {
      name: "size",
      fieldOrFunction: "items.name"
    },
    {
      name: "color",
      fieldOrFunction: "var_fedas.color"
    }

    //TODO: add occasion filters
  ];
  const cardFormatter = formatProductCard;
  return {
    cardFormatter,
    category,
    filterGroups,
    productIds,
    productsPerPage: count
  };
};

export const getProductsFilterParameters = (
  categories: string[] = [],
  productIds: string[] | undefined = undefined,
  count: number = defaultCount
): GetProductsParameters => {
  const parameteres: GetProductsParameters = getFilterParameters(
    count,
    productIds,
    categories
  );
  return parameteres;
};
